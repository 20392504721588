import { FunctionalComponent, h } from 'preact';
import { Text } from 'preact-i18n';
import { Theme } from '../../contexts/Theme';
import style from "./style.scss";


const Footer: FunctionalComponent = () => {

    return (
        <Theme.Consumer>
            {
                (theme) =>
                    <footer class={style.Footer} style={{ backgroundColor: theme.color }}>
                        © {new Date().getFullYear()} Rice Me <Text id="components.footer.copy" />
                    </footer>
            }
        </Theme.Consumer>
    );
}

export default Footer;