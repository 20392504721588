
		import Async from "../../../node_modules/.pnpm/@preact+async-loader@3.0.1_preact@10.11.3/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../node_modules/.pnpm/babel-loader@8.3.0_uwaygiumfr5ruezn5bfsw34234/node_modules/babel-loader/lib/index.js??ref--4!./index.tsx");
				typeof cb === 'function' && cb(result);
			}, "route-restaurant");
		}

		export default Async(load);
	