import Deli from './routes/deli';
import Home from './routes/home';
import Events from './routes/events';
import Restaurant from './routes/restaurant';



const routes: (Route | DefaultRoute)[] = [

    {
        path: "/restaurant",
        key: "restaurant",
        navLogo: "/assets/logos/restaurant-nav.svg",
        homeLogo: "./assets/logos/restaurant-home.svg",
        homeImage: "./assets/images/riceme/arroznero.jpeg",
        component: Restaurant,
        color: "#509b67",
        navOrder: 1
    },
    {
        path: "/deli",
        key: "deli",
        component: Deli,
        homeLogo: "./assets/logos/deli-home.svg",
        homeImage: "./assets/images/deli/bolodeli1.jpeg",
        navLogo: "/assets/logos/deli-nav.svg",
        color: "#f46e3a",
        navOrder: 2
    },
    // {
    //     path: "/events",
    //     key: "events",
    //     component: Events,
    //     homeLogo: "./assets/logos/events-home.svg",
    //     homeImage: "./assets/images/events/people.jpeg",
    //     navLogo: "/assets/logos/events-nav.svg",
    //     color: "#bdaa8f",
    //     navOrder: 3
    // },
    {
        path: "/",

        component: Home,
        default: true
    }];

export default routes;