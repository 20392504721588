import { useState } from 'preact/hooks';

const providedLanguages: Language[] = ["pt-pt"];

const getUserLanguage = (): Language => {
    let res: string | undefined;
    if (typeof window !== "undefined") {
        res = localStorage.getItem("language") || navigator.languages.find(ul =>
            providedLanguages.some(p =>
                p === ul.toLowerCase() || p.startsWith(ul.toLowerCase())))?.toLowerCase();
    }

    return (res || providedLanguages[0]) as Language;
}
const defaultLang = getUserLanguage();

const languageDefinitions: LanguageDefinition[] = [];

const useLanguage = (): [LanguageDefinition | undefined, ((language: Language) => void)] => {

    const [lang, setLang] = useState<LanguageDefinition | undefined>();

    const setLanguage =
        (language: Language): void => {

            if (typeof window !== "undefined") {

                localStorage.setItem("language", language);
            }

            const savedLanguage = languageDefinitions.find(l => l.key === language);

            if (savedLanguage) {
                setLang(savedLanguage);
            } else if (typeof window !== "undefined") {
                fetch(`/i18n/${language}.json`)
                    .then(res => res.json())
                    .then(json => {
                        const newLanguage = { key: language, definition: json };
                        languageDefinitions.push(newLanguage)
                        setLang(newLanguage);
                    });
            }
        };

    if (!lang) {
        setLanguage(defaultLang);
    }

    return [lang, setLanguage];
};


export { useLanguage, providedLanguages };