import { h, Fragment } from 'preact';
import { useText, withText } from 'preact-i18n';
import { CarouselItem } from './components/carousel';

function IFF<T>(evaluate: T | (() => T), element: ((e: Truthy<T>) => JSX.Element | JSX.Element[])): JSX.Element {
    if (evaluate) {
        if (typeof evaluate === 'function' && (evaluate = (evaluate as CallableFunction)())) {

            return (
                <Fragment>
                    {element(evaluate as Truthy<T>)}
                </Fragment>
            );
        }
        return (
            <Fragment>
                {element(evaluate as Truthy<T>)}
            </Fragment>
        )
    }
    return <Fragment />
}
type Truthy<T> = T extends boolean
    ? true
    : T extends infer P | undefined ?
    NonNullable<P> : never;

function withCarouselIntl(slides: Record<string, unknown>): JSX.Element[] {
    return Object.keys(slides).map(key => {
        const src = slides[key];

        const Title = withText(`slides.${key}.title`)(
            ({ title }: { title: string }) => title
                ? <h1>{title}</h1>
                : <Fragment />
        );

        const Text = withText(`slides.${key}.text`)(({ text }: { text: string }) => text
            ? <p>{text}</p>
            : <Fragment />
        );


        if (!useText(`slides.${key}.title`).title && !useText(`slides.${key}.text`).text) {
            return <CarouselItem key={key} src={src as string} />
        }

        return (
            <CarouselItem key={key} src={src as string} >
                <Title />
                <Text />
            </CarouselItem>
        )
    })
}

function isMobile(): boolean {
    if (typeof window !== "undefined") {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    return false;
}
function getImageSrc(src: string): string {
    if (isMobile()) {
        // true for mobile device
        return src.replace('.jpeg', '_mobile.jpeg');
    }
    return src;
}

export { IFF, withCarouselIntl, isMobile, getImageSrc };

