import { RefObject, createRef } from 'preact';
import { useEffect } from 'preact/hooks';

interface JSXElementBase {
    base: HTMLElement
}

const withHtmlElement = (wrapped: JSX.Element, execute: (elementBase: HTMLElement) => void, dependencies: any[] = []) => {

    const ref = ((wrapped as { ref?: RefObject<JSXElementBase> }).ref || createRef<JSXElementBase>());

    (wrapped as { ref?: RefObject<JSXElementBase> }).ref ||= ref;

    useEffect(() => {

        if (ref.current) {
            if (ref.current instanceof HTMLElement) {
                return execute(ref.current);
            }
            else if (ref.current.base instanceof HTMLElement) {
                return execute(ref.current.base);
            }
        }

    }, [ref.current, ...dependencies])

    return wrapped;
}

export default withHtmlElement;