import { useContext } from 'preact/hooks';
import blackToColor from '../color-to-filter';
import { Theme } from '../contexts/Theme';
import withHtmlElement from './html-element';

const withBackgroundImageScrollEffect = (wrapped: JSX.Element, identifier = "body", factor = 8) => {

    return withHtmlElement(wrapped, (element) => {

        const elem = document.querySelector(identifier);

        if (!elem) {
            throw "No scrolling element provided";
        }

        const handleScroll = () => {

            const refTop = element.getBoundingClientRect().top;

            const diff = element.scrollTop - refTop;

            element.style.backgroundPositionY = `calc(50% + ${diff / factor}px)`;
        }

        elem.addEventListener("scroll", handleScroll);


        return () => elem.removeEventListener("scroll", handleScroll);
    });
}

export default withBackgroundImageScrollEffect;