import { createContext, Fragment, FunctionalComponent, h } from 'preact';
import { IntlProvider } from 'preact-i18n';
import { getCurrentUrl, Route, Router } from 'preact-router';
import { useState } from 'preact/hooks';
import { Theme } from '../contexts/Theme';
import { useLanguage } from '../hooks/language';
import routes from '../routes';
import NotFoundPage from '../routes/notfound';
import { IFF } from '../utils';
import Footer from './footer';
import Header from './header';
import Newsletter from './newsletter';



const App: FunctionalComponent = () => {

    const [url, setCurrentUrl] = useState<string | undefined>(undefined);

    const [language, setLanguage] = useLanguage();

    const currentRoute = routes.filter(r => !r.default).find(r => r.path === url) as (Route | undefined);

    return (
        <div id="preact_root" >
            <Theme.Provider value={{ color: currentRoute?.color }}>
                <IntlProvider definition={language?.definition}>
                    {IFF(currentRoute, () => <Header url={url} onLanguageUpdate={setLanguage} language={language} />)}
                    <main class={currentRoute ? "" : "expanded"}>
                        <Router onChange={(): void => setCurrentUrl(getCurrentUrl())}  >
                            {routes.map(r => <Route key={r.path} component={r.component} path={r.path} />)}
                            <NotFoundPage default />
                        </Router>
                    </main>
                    {IFF(currentRoute, () => <Fragment><Footer /></Fragment>)}
                </IntlProvider>
            </Theme.Provider>
        </div>
    );
};

export default App;
