import { ComponentChildren, Fragment, FunctionalComponent, h, VNode } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import withBackgroundImageScrollEffect from '../../hocs/background-image-scroll-effect';
import { getImageSrc } from '../../utils';
import style from "./style.scss";

interface Props {
    children: VNode<typeof CarouselItem>[] | VNode<typeof CarouselItem>;
    interval?: number;
}

interface State {
    inView: [number | undefined, number];
    first: boolean;
}

const Carousel: FunctionalComponent<Props> = ({ children, interval }: Props) => {

    const descendants = Array.isArray(children) ? children : [children];
    const [state, setState] = useState<State>({ inView: [undefined, 0], first: true })
    useEffect(() => {

        let timeout: NodeJS.Timer | undefined;

        if (descendants.length > 1) {

            timeout = setTimeout(() => {

                const previous = state.inView[1];

                const next = previous === (descendants.length - 1)
                    ? 0
                    : previous + 1;

                setState({ inView: [previous, next], first: false })
            }, interval || 6000)
        }

        return (): void => { if (timeout) clearTimeout(timeout) }
    }, [state, descendants?.length, interval])

    return (
        <div class={style.Carousel} >
            <Fragment>
                {
                    descendants.map((child, i) => {

                        const className = i === state.inView[0]
                            ? style.OutCarouselItem
                            : i === state.inView[1]
                                ? state.first ? style.CarouselItem
                                    : style.RightCarouselItem
                                : style.HiddenCarouselItem;
                        child.key = i;

                        const props = child.props as unknown as ExtendedCarouselItemProps;

                        props.className = className;
                        return <CarouselItem key={i} {...props} />
                    }
                    )
                }
            </Fragment>
        </div>
    )
}
interface CarouselItemProps {
    src: string;
    children?: ComponentChildren
}
interface ExtendedCarouselItemProps extends CarouselItemProps {
    className: string;
}

const CarouselItem: FunctionalComponent<CarouselItemProps> = (props: CarouselItemProps) => {

    const { src, children, className } = (props as ExtendedCarouselItemProps);

    if (Array.isArray(children)) {
        console.log(children)
    }
    const styleObj: h.JSX.CSSProperties = { backgroundImage: `url('${getImageSrc(src)}')`, width: "100%", height: "100%" };

    if (!children) {
        styleObj.backgroundColor = "unset";
    }
    return (
        withBackgroundImageScrollEffect(<div class={className} style={styleObj}>
            {children}
        </div>, undefined, 2)
    )
}

export { Carousel, CarouselItem };