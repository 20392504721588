import { createRef, FunctionalComponent, h, RefObject } from 'preact';
import { getCurrentUrl } from 'preact-router';
import { Link } from 'preact-router/match';
import { useEffect, useState } from 'preact/hooks';
import withOutsideClickCallback from '../../hocs/outside-click';
import { providedLanguages } from '../../hooks/language';
import routes from '../../routes';
import { IFF, isMobile } from '../../utils';
import MobileMenu from '../mobile-menu';
import style from './style.scss';

interface Props {
    url?: string;
    onLanguageUpdate: ((lang: Language) => void);
    language?: LanguageDefinition;
}

const Header: FunctionalComponent<Props> = ({ url, onLanguageUpdate, language }: Props) => {

    const [navVisibility, setNavVisibility] = useState(false);
    const [languageVisibility, setLanguageVisibility] = useState(false);

    const handleNavClick = (): void => {
        setNavVisibility(!navVisibility)
    };
    const handleLanguageClick = (): void => {
        setLanguageVisibility(!languageVisibility)
    };

    const currentRoute = routes.find(r => r.path === url) as Route;

    return isMobile() && navVisibility
        ? <MobileMenu onClick={(e, r) => {
            setTimeout(() => setNavVisibility(false), 150);
            if (r.path == getCurrentUrl()) {
                e.preventDefault();
            }
        }} />
        : (
            <header class={style.Header}>
                {
                    IFF(currentRoute, (route) =>
                        withOutsideClickCallback(
                            <div class={style.ActiveRoute} onClick={handleNavClick}>
                                <img src={route.navLogo} />
                                {
                                    IFF(navVisibility, () =>
                                        <div class={style.Nav} >
                                            {(routes
                                                .filter(r => r !== currentRoute && !r.default) as Route[])
                                                .sort((a, b) => a.navOrder < b.navOrder ? -1 : 1)
                                                .map(r =>
                                                    <Link key={r.path} href={r.path} class="Link" onClick={(e): void => e.preventDefault()}>
                                                        <img src={r.navLogo} />
                                                    </Link>)}
                                        </div>
                                    )}
                            </div>
                            , () => setNavVisibility(false)
                            , navVisibility
                        )
                    )
                }
                {
                    IFF(providedLanguages.length > 1, () =>
                        withOutsideClickCallback(
                            <div class={style.ActiveLanguage} onClick={handleLanguageClick}>
                                {language?.key.split("-")[0].toUpperCase()}
                                {IFF(languageVisibility, () =>
                                    <div class={style.Language} >
                                        {providedLanguages.filter(p => p !== language?.key).map(p =>
                                            <div key={p} onClick={(): void => onLanguageUpdate(p)}>
                                                {p.split("-")[0].toUpperCase()}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            , () => setLanguageVisibility(false)
                            , languageVisibility
                        ))

                }

            </header>
        );
};

export default Header;
