import withHtmlElement from './html-element';

const withOutsideClickCallback = (wrapped: JSX.Element, exec:(()=> void), beginImmediately:boolean = true) => {

    return withHtmlElement(wrapped, (element) => {

        const handleOutsideClick = (e: MouseEvent): void => {
                if (e.target !== element && !element.contains(e.target as any)) {
                    document.removeEventListener("mousedown", handleOutsideClick);
                    exec();
                }
            }
            if (beginImmediately) {
                document.addEventListener("mousedown", handleOutsideClick);
            }

            return (): void => document.removeEventListener("mousedown", handleOutsideClick);
    }, [beginImmediately])
}

export default withOutsideClickCallback;