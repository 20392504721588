import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router';
import Image from '../../components/image';
import routes from '../../routes';
import { getImageSrc } from '../../utils';
import style from './style.scss';

interface Props {
    onClick?: (e: MouseEvent, r: Route) => void;
}

const MobileMenu: FunctionalComponent<Props> = ({ onClick }: Props) => {
    const validRoutes = routes.filter(r => !r.default) as Route[];

    return (
        <nav class={style.TwoItems} >
            {validRoutes.map(r =>
                <Link key={r.path} href={r.path} class="Link" onClick={onClick ? (e) => onClick(e, r) : undefined}
                    style={{ backgroundImage: `url('${getImageSrc(r.homeImage)}')` }} target="">
                    <img class="Logo" src={r.homeLogo} alt="Logo" />
                </Link>
            )}
        </nav>
    );
};

export default MobileMenu;
